'use-strict';

export const slider = () => {
    const sliders = document.querySelectorAll('.fade-slider');

    for (let i = 0; i < sliders.length; i++) {
        const fs = sliders[i];
        const interval = parseInt(fs.getAttribute('data-interval')) || 6000;
        const images = fs.querySelectorAll('.fade-slide');

        setInterval(() => {
            for (let i = 0; i < images.length; i++) {
                const el = images[i];
                
                if (el.classList.contains('active-slide')) {
                    el.classList.remove('active-slide');
                    const nextIndex = i + 1;

                    if (nextIndex < images.length) {
                        images[nextIndex].classList.add('active-slide');
                    } else {
                        images[0].classList.add('active-slide');
                    }

                    break;
                }
            }
        }, interval);
    }
};